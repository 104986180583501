import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
const LoadingDialog = ({ title }) => {
  return (
    <div className="loading-dialog">
      <Box sx={{ display: 'flex' }}>
        <CircularProgress color="success" />
      </Box>
    </div>
  );
};

export default LoadingDialog;
