import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Dashboard from "./Dashboard";
import InitLogo from "./InitLogo";
import SignIn from "./SignIn";
import { Provider } from "react-redux";
import ThemeProvider from "./theme/ThemeProvider";
import store from "./store";
import "./App.css";
import PrivateRoute from "./PrivateRoute";

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <Router>
          <Routes>
            <Route
              path="/dashboard"
              element={<PrivateRoute element={<Dashboard />} />}
            />
            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            <Route path="/login" element={<SignIn />} />
            <Route path="/" element={<InitLogo />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
