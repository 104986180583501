// DynamicTable.js
import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Container,
  Select,
  MenuItem
} from '@mui/material';
import axiosApiInstance from '../config/axiosApi';
import LoadingDialog from '../components/loading';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Pagination } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    paginationContainer: {
      display: "flex",
      alignItems: "center",
    },
    noBlur: {
      '& tbody tr:hover': {
        opacity: '1 !important'
      }
    },
    addButton: {
      position: 'absolute !important',
      top: theme.spacing(2),
      right: theme.spacing(2),
    }
  }));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DynamicTable = () => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [newRow, setNewRow] = useState({ name: '', email: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [snackBarTitle, setSnackBarTitle] = React.useState('');
  const [clientPagination, setClientPagination] = useState({
    page: 1,
    total_pages: 1,
  });

  const carriers = ['ATT', 'TMobile', 'Verizon'];

  const fetchClientList = async () => {
    setIsLoading(true);
    try {
      await axiosApiInstance
        .post('/get_clients', { page: clientPagination.page })
        .then((response) => {
          setRows(response.data.clients);
          setClientPagination({
              ...clientPagination,
              total_pages: response.data.totalPages,
          });
        })
    } catch(error) {
       console.error("Error fetching notification history:", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    // Fetch saved data from the database on component mount
    
    fetchClientList();
    
  }, []);

  const handleClientPageChange = (event, value) => {
    setClientPagination({ ...clientPagination, page: value });
  };

  const addRow = () => {
    if (
      (rows.length > 0 && rows[rows.length - 1]?.name == '') ||
      rows[rows.length - 1]?.email == ''
    ) {
      return;
    }

    let newId;
    if (rows.length == 0)
      newId = 1;
    else
      newId = rows[rows.length - 1]?.id + 1;
    console.log('Set New Row', {
      id: newId,
      name: '',
      email: '',
      phone_number: '',
      cellular_carrier:''
    });
    setRows([...rows, { id: newId, name: '', email: '', phone_number: '', cellular_carrier: '' }]);
    setNewRow({ id: newId, name: '', email: '', phone_number: '', cellular_carrier: '' });
  };

  const deleteRow = async (id) => {
    try {
      setIsLoading(true);
      const response = await axiosApiInstance.post('/delete_client', {
        id: id
      });

      const updatedRows = rows.filter((row) => row.id !== id);
      setRows(updatedRows);

      setSnackBarOpen(true);
      setSnackBarTitle('Success');
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const saveRow = async (row) => {
    if (
      row.name === '' ||
      row.email === '' ||
      row.phone_number === '' ||
      row.cellular_carrier === ''
    ) {
      setSnackBarOpen(true);
      setSnackBarTitle('Please fill in all fields before saving.');
      return;
    }

    setIsLoading(true);
    try {
      const response = await axiosApiInstance.post(
        '/save_client',
        row
      );
      setIsLoading(false);
      setSnackBarOpen(true);
      setSnackBarTitle('Success');
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (id, key, value) => {
    const filterRows = rows.map((row) => {
      if (row.id == id) {
        let cloneRow = { ...row };
        cloneRow[key] = value;
        return cloneRow;
      } else {
        return row;
      }
    });

    setRows(filterRows);
  };

  return (
    <div>
      {isLoading && <LoadingDialog title="" />}
      <div class="add-btn-wrapper">
        <Button variant="contained" color="primary" onClick={addRow} >
          Add User
        </Button>
      </div>
      <Table className={classes.noBlur}>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell>Cellular Carrier</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={row._id} className={classes.noBlur}>
              <TableCell>
                {(clientPagination.page - 1) * 20 + index + 1}
              </TableCell>
              <TableCell>
                <TextField
                  type="text"
                  value={row.name}
                  onChange={(e) =>
                    handleInputChange(row.id, 'name', e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <TextField
                  type="email"
                  value={row.email}
                  onChange={(e) =>
                    handleInputChange(row.id, 'email', e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <TextField
                  type="text"
                  value={row.phone_number}
                  onChange={(e) =>
                    handleInputChange(row.id, 'phone_number', e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <Select
                  value={row.cellular_carrier}
                  onChange={(e) =>
                    handleInputChange(row.id, 'cellular_carrier', e.target.value)
                  }
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {carriers.map((carrier) => (
                    <MenuItem key={carrier} value={carrier}>{carrier}</MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => saveRow(row)}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => deleteRow(row.id)}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={clientPagination.total_pages}
        page={clientPagination.page}
        onChange={handleClientPageChange}
        className={classes.paginationContainer}
        />
      <div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={snackBarOpen}
          autoHideDuration={5000}
          onClose={() => {
            setSnackBarOpen(false);
          }}
        >
          <Alert
            onClose={() => {
              setSnackBarOpen(false);
            }}
            severity="info"
            sx={{ width: '100%', color: 'white' }}
          >
            {snackBarTitle}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default DynamicTable;
