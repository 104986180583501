import React, { useState, useEffect } from "react";
import axiosApiInstance from "./config/axiosApi";
import { useDispatch } from "react-redux";
import { logout } from "./store/authSlice";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  Tabs,
  Tab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Grid
} from "@mui/material";
import { Pagination } from "@mui/material";
import { makeStyles } from "@mui/styles";
import constants from "./config/constants";
import DynamicTable from "./components/DynamicTable";
import Logo from "./logo.png";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginBottom: theme.spacing(4),
  },
  tabContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2)
  },
  paginationContainer: {
    display: "flex",
    alignItems: "center",
  },
  logoutButton: {
    position: 'absolute !important',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  tab: { 
    minWidth: 100, // Minimum width of each tab
    width: 'auto', // This can be changed according to your layout needs
  }
}));

function Dashboard() {
  const classes = useStyles();
  const [devices, setDevices] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [selectedId, setSelectedId] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [devicePagination, setDevicePagination] = useState({
    page: 1,
    total_pages: 1,
  });
  const [alertPagination, setAlertPagination] = useState({
    page: 1,
    total_pages: 1,
  });

  const dispatch = useDispatch();
  const baseURL = constants.baseURL;
  const socket = new WebSocket(constants.socketURL);

  useEffect(() => {
    setupWebSocket();
  }, []);

  useEffect(() => {
    fetchDeviceList();
    fetchAlertHistory();
    const alertInterval = setInterval(fetchDeviceList, 60000); // Fetch alerts every 1 minute
    return () => clearInterval(alertInterval); // Cleanup function to clear interval on component unmount
  }, [tabValue, devicePagination.page, alertPagination.page]);

  const setupWebSocket = () => {
    socket.onopen = () => console.log("WebSocket connected");
    socket.onmessage = (event) => handleMessage(event.data);
    socket.onerror = (error) => console.error("WebSocket error:", error);
    socket.onclose = (event) => console.log("WebSocket disconnected:", event);
  };

  const redirectToGmap = (alert) => {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${alert.lat},${alert.lon}`
    );
  };

  const handleMessage = (message) => {
    const payload = JSON.parse(message);
    console.log(payload);
    setSelectedId(payload.id);
    if (
      payload.type === "New Photo Detected" ||
      payload.type === "New Video Detected" ||
      payload.type === "Device Status Changed"
    ) {
      console.log(payload, tabValue);
      toastr.error(payload.message, "Notification", {
        positionClass: "toast-top-right",
        closeButton: true,
        timeOut: 60000,
      });
      setTabValue(1);
      fetchAlertHistory();
    } else if (
      payload.type === "Start service" ||
      payload.type === "Stop service" ||
      payload.type === "Login detection"
    ) {
      toastr.error(payload.message, "Notification", {
        positionClass: "toast-top-right",
        closeButton: true,
        timeOut: 60000,
      });
      fetchDeviceList();
    }
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleDevicePageChange = (event, value) => {
    setDevicePagination({ ...devicePagination, page: value });
  };

  const handleAlertPageChange = (event, value) => {
    setAlertPagination({ ...alertPagination, page: value });
  };

  const fetchDeviceList = () => {
    axiosApiInstance
      .post(`${baseURL}check_devices`, { page: devicePagination.page })
      .then((response) => {
        setDevices(response.data.devices);
        setDevicePagination({
          ...devicePagination,
          total_pages: response.data.totalPages,
        });
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const fetchAlertHistory = () => {
    axiosApiInstance
      .post(`${baseURL}get_alerts`, { page: alertPagination.page })
      .then((response) => {
        setAlerts(response.data.alerts);
        setAlertPagination({
          ...alertPagination,
          total_pages: response.data.totalPages,
        });
      })
      .catch((error) => console.error("Error fetching alert history:", error));
  };

  return (
    <div className="device-dashboard" style={{ position: 'relative', height: '100vh' }}>
      <Button
          variant="contained"
          color="primary"
          className={classes.logoutButton}
          onClick={() => {
            dispatch(logout());
          }}
        >
          Logout
      </Button>

      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <img src={Logo} style={{ width: '200px', height: '200px' }} />
      </Grid>
      
      <div className="dashboard-tabs">
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          className={classes.tabContainer}
          variant="fullWidth"
        >
          <Tab label="Users" className={classes.tab} />
          <Tab label="Alerts" className={classes.tab} />
          <Tab label="Notifications" className={classes.tab} />
        </Tabs>
        
        
      </div>
      {tabValue === 0 && (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Project Name</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {devices?.map((device, index) => (
                <TableRow
                  key={device.phone_number}
                  className={`${
                    selectedId == device.phone_number ? "highlighted" : ""
                  } ${device.is_online ? "online-row" : "offline-row"}`}
                >
                  <TableCell>
                    {(devicePagination.page - 1) * 20 + index + 1}
                  </TableCell>
                  <TableCell>{device.user_name}</TableCell>
                  <TableCell>{device.email}</TableCell>
                  <TableCell>{device.project_name}</TableCell>
                  <TableCell>{device.phone_number.split("_")[1]}</TableCell>
                  <TableCell>
                    {device.is_online ? "Online" : "Offline"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={devicePagination.total_pages}
            page={devicePagination.page}
            onChange={handleDevicePageChange}
            className={classes.paginationContainer}
          />
        </TableContainer>
      )}
      {tabValue === 1 && (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Project Name</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Alert Time</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {alerts?.map((alert, index) => (
                <TableRow
                  key={alert.id}
                  className={selectedId == alert.id ? "highlighted" : ""}
                  onClick={() => {
                    redirectToGmap(alert);
                  }}
                >
                  <TableCell>
                    {(alertPagination.page - 1) * 20 + index + 1}
                  </TableCell>
                  <TableCell>{alert.user_name}</TableCell>
                  <TableCell>{alert.user_email}</TableCell>
                  <TableCell>{alert.project_name}</TableCell>
                  <TableCell>{alert.phone_number.split("_")[1]}</TableCell>
                  <TableCell>
                    {new Date(alert.device_time).toLocaleString()}
                  </TableCell>
                  <TableCell>{alert.type}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={alertPagination.total_pages}
            page={alertPagination.page}
            onChange={handleAlertPageChange}
            className={classes.paginationContainer}
          />
        </TableContainer>
      )}
      {tabValue === 2 && (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <DynamicTable />
        </TableContainer>
      )}
    </div>
  );
}

export default Dashboard;
