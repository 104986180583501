import React from "react";
import { Link } from "react-router-dom";
import Logo from "./logo.png";

function InitLogo() {
  return (
    <div className="logo-dashboard">
      <Link to="/login">
        <img src={Logo} className="logo" alt="Logo" />
      </Link>
    </div>
  );
}

export default InitLogo;
