const constants = {
  baseURL: "https://b870k1eggc.execute-api.us-east-1.amazonaws.com/prod/",
  socketURL:
    "wss://25n9w3cyue.execute-api.us-east-1.amazonaws.com/set_guard_prod",
  // baseURL: "https://b870k1eggc.execute-api.us-east-1.amazonaws.com/ua/",
  // socketURL: "wss://25n9w3cyue.execute-api.us-east-1.amazonaws.com/set_guard",
  // baseURL: "http://localhost:7575/api/",
};

export default constants;
